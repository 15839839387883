import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToAutomaticallySendDmForTwitterCampaigns = ({
    location
  }) => {
  const title =
    "投稿のリツイート後にDMを自動送信する方法は？Twitterキャンペーンで一括配信できるツールを解説"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="投稿のリツイート後にDMを自動送信する方法は？Twitterキャンペーンで一括配信できるツールを解説"
          pagedesc="TwitterのDMを自動送信する方法を紹介します。Twitter社のルールやガイドラインを遵守した上で、リスクなくDMを一括送信することができます。最新状況をチェックし、ビジネス目標につながるキャンペーンを企画しましょう。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-automatically-send-dm-for-twitter-campaigns.jpeg"
          pagepath="/blog/how-to-automatically-send-dm-for-twitter-campaigns"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              投稿のリツイート後にDMを自動送信する方法は？Twitterキャンペーンで一括配信できるツールを解説
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年5月13日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="手の上のメールアイコン"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-automatically-send-dm-for-twitter-campaigns.jpeg"
                className="w-full"
              />
            </figure>
            <h2 id="id1">
              大変なDM送信作業、なんとかならない？
            </h2>
            <p>
              Twitter（ツイッター）の企業アカウントを運用する上で、大量のDM（ダイレクトメール）を一人ひとりに送るのは、とても時間がかかります。Twitterキャンペーンの当選結果やユーザーへのお知らせを自動送信できれば、担当者の手間がなくなるのはもちろん、DMの誤送付を防ぐことにもつながります。
            </p>
            <p>
              しかし、Twitter社が定めているDMの自動送信ルールに違反していると見なされると、最悪の場合アカウントを凍結されてしまうことも。キャンペーン中にTwitterの利用が止められてしまうリスクも想定されます。
            </p>
            <h2 id="id2">
              リスクを抑えてDMを自動送信するなら
            </h2>
            <p>
              そこで今回は、Twitter社のルールを遵守した上でリスクなくDMを自動化する方法をご紹介します。
            </p>
            <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>
                  Twitter ハッシュタグキャンペーンを開催しようと考えている方
                </li>
                <li>
                  Twitter DM送信を効率化したい方
                </li>
                <li>
                  Twitter キャンペーンのリスクについて知りたい方
                </li>
              </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    大変なDM送信作業、なんとかならない？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    リスクを抑えてDMを自動送信するなら
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. TwitterでDMを自動送信できるシーン
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id4" className="toc__link">
                        Twitterキャンペーンの当選通知
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        ユーザーへのお知らせ送信
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id6" className="toc__link">
                    2. DM（ダイレクトメッセージ）を一括配信するときの注意点
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        Twitterの自動化ルール
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id8" className="toc__link">
                    3. Twitter社が定めているDM送信数のリミット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        Twitter DM の送信上限は1日500件、自動なら50件を目安に
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id10" className="toc__link">
                        Twitterでキャンペーンを開催する際はガイドラインを守る
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id11" className="toc__link">
                    4. 投稿のリツイート後にDMを自動配信する方法
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        自動化システムを自分で組む
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        自動化ツールを使う
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id14" className="toc__link">
                    Twitter DM 運用についてのまとめ
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. TwitterでDMを自動送信できるシーン</h2>
              <p>
                Twitterアカウントの運用でDMを自動送信すると便利なのは、次のようなシーンです。
              </p>
              <ul>
                <li>
                  Twitterキャンペーンの当選通知
                </li>
                <li>
                  ユーザーへのお知らせ送信
                </li>
              </ul>
              <p>
                それぞれの活用シーンを詳しく見ていきましょう。
              </p>
              <h3 id="id4">Twitterキャンペーンの当選通知</h3>
              <p>
                「twitter DM 自動化」などのキーワードを検索すると、同じ内容のテキストを複数のユーザーに配信できる方法が出てくることがあります。しかし、この方式では「当選者100名にAmazonギフトコードを送る」といったケースに活用できません。ギフトコードがそれぞれ異なり、1件ずつ内容を変える必要があるからです。
              </p>
              <p>
                このような課題を解決できるTwitterの標準機能は存在しないため、手動で地道に入力することも。慎重に作業すれば100通くらいまではなんとか送信できますが、1000通となると人為的なミスがどうしても発生してしまいます。
              </p>
              <p>
                また、手動のDMは想像以上に時間がかかります。1通の送信に40秒かかったとして、1,000通では40,000秒、時間に換算するとなんと10時間以上。DMの自動送信を活用すれば、このような作業が一気になくなり、ミスなく効率的なTwitter運用を実現できます。
              </p>
              <h3 id="id5">ユーザーへのお知らせ送信</h3>
              <p>
                DMの一括送信ができれば、大切な情報を多くのユーザーへお知らせすることもできます。例えば、イベントの出欠確認やセールの告知など、たくさんの人に同じ内容を伝えるときにも便利です。
              </p>
              <p>
                一般公開して問題ない情報であれば、Twitterの標準機能である「グループDM」も同じように利用できます。「グループDM」は、複数のユーザー間でやり取りできる機能のこと。1つのグループに追加可能な人数は50人までなので、それ以上に一斉送信する場合は、グループを追加して送信する必要があります。
              </p>
              <p>
                ただし、「グループDM」に適しているのはややプライベート寄りの内容なので、日々の連絡や小規模グループの告知に活用するのがおすすめです。DMの一括送信とは使い分けた方がいいでしょう。
              </p>
            </div>
            <div>
              <h2 id="id6">2. DM（ダイレクトメッセージ）を一括配信するときの注意点</h2>
              <p>
                TwitterでDMを一括送信することにリスクはないのかどうか、気になる方もいらっしゃると思います。冒頭でご紹介したように、Twitter社のルールに違反して一方的にDMを自動送信すると、アカウントが凍結されるリスクがあります。
              </p>
              <p>
                DMを自動化する際には、Twitter社のルールを遵守するようにしましょう。規定や方針が頻繁に変更されるので、最新情報を定期的にチェックすることが大切です。（以下の情報は2023年5月現在の内容）
              </p>
              <h3 id="id7">Twitterの自動化ルール</h3>
              <p>
                Twitter社が開発者向けに提供しているAPI※自体に、DMを自動送信するための機能が用意されています。このことからも、ルールを守って利用すれば、DMを自動化しても問題ないことが理解できます。
              </p>
              <p>
                ※API……ソフトウェアやプログラムをつなぐためのインターフェイス。本記事の場合、Twitterを企業や個人がシステムと連動して利用するためにTwitterが用意した仕組みのこと。
              </p>
              <p>
                Twitter社の
                <a
                  href="https://help.twitter.com/ja/rules-and-policies/twitter-automation"
                  target="_blank"
                  rel="noreferrer"
                >
                  「自動化ルール」
                </a>
                から一部抜粋してご紹介すると、「行ってもよいこと」の項目に下記のような記載があります。
              </p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    自社のコンテンツに反応したアカウントに自動返信するクリエイティブキャンペーンを実施する。
                  </li>
                  <li>
                    ダイレクトメッセージで利用者に自動返信するソリューションを開発する。
                  </li>
                  <li>
                    利用者の役に立つ新しいサービスに挑戦する（ただし、Twitterのルールを遵守する）。
                  </li>
                </ul>
              </div>
              <p>
                上記の内容から、DMの自動送信もスパムのような目的・内容でなければ、問題ないと考えられます。
              </p>
            </div>
            <div>
              <h2 id="id8">3. Twitter社が定めているDM送信数のリミット</h2>
              <h3 id="id9">Twitter DM の送信上限は1日500件、自動なら50件を目安に</h3>
              <p>
                Twitter社ではシステムの負担を軽くし、ダウンタイムやエラーページの発生を抑えるため、ダイレクトメッセージ送信回数に上限を設定しています。
              </p>
              <p>
                DM送信の際は、2つのリミット（制限）に注意する必要があります。
              </p>
              <p>
                まず手動で送るDMに関するリミットに関しては、以下のように定められています。
              </p>
              <p>
                ダイレクトメッセージ（1日）: 1日に送信できるメッセージは500件に制限されています。
              </p>
              <p>
                以前は1日1,000通までDMを送信できていましたが、2023年5月現在は500通の制限が定められています。リミットに達するとエラーメッセージが表示されてDM送信ができなくなります。制限は1日単位なので通常24時間後までに解除されますが、場合によっては数日待たされるケースもあるようです。
              </p>
              <p>
                Twitterキャンペーンの開催にあたっては、DM送信数が1日500件に達しないように注意する必要があります。途中でエラーが起こらないように、DMを何日かに分けることも検討しておいた方がいいでしょう。
              </p>
              <p>
                次に、ツールを使ってDMを自動送信する場合です。インスタントウィンキャンペーン等でDM送信を自動で行う場合は、1日あたり50件が上限となります。当選者数を50名以下にしたり、当選確率を調整するなど、企画側で工夫する必要があるでしょう。
              </p>
              <h3 id="id10">Twitterでキャンペーンを開催する際はガイドラインを守る</h3>
              <p>
                Twitterキャンペーンを実施するときには、Twitter社が定めているガイドラインも確認するようにしましょう。ユーザーとのコミュニケーションにおいて重要なポイントは以下の3つです。
              </p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    複数アカウントを作成させない
                  </li>
                  <li>
                    繰り返し同じツイートをさせない
                  </li>
                  <li>
                    キャンペーンに関連する話題を盛り込むように推奨する
                  </li>
                </ul>
              </div>
              <p>
                ガイドラインの遵守は主催者の責任となります。不安な場合はTwitterキャンペーンに詳しい事業者や広告代理店、あるいはTwitter本社に問い合わせるようにしましょう。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/2021-campaign-result"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンの成功の秘訣は？PARKLoT公式アカウントのキャンペーン結果を大公開！！
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id11">4. 投稿のリツイート後にDMを自動配信する方法</h2>
              <p>TwitterのDMを自動送信するには、いくつかの方法があります。</p>
              <h3 id="id12">自動化システムを自分で組む</h3>
              <p>
                ソフトウェア開発の知識があれば、自分や周囲のエンジニアを開発者として申請し、Twitter APIを利用して自らプログラムを記述することもできます。社内に頼れる専門家がいる場合や、自分でプログラムを書くことができる場合にはオススメです。
              </p>
              <p>
                Twitterが提供しているTwitter API には、プログラムを使ってDMを送信するAPIが用意してあります。このAPIを使えば指定したユーザーに一斉にDMを送ることができます。
              </p>
              <p>
                ただし、ソフトウェアに関する専門的な知識が必要となるため、Twitter API を利用するハードルはかなり高いでしょう。
              </p>
              <h3 id="id13">自動化ツールを使う</h3>
              <p>
                Twitterの一括DM配信ツールを提供している事業者に依頼するのが、最も手間のかからない効率的な方法です。費用は少しかかりますが、一度ツール利用の契約を結べば、手間をかけずに自動でDMを一括送信することができます。
              </p>
              <p>
                手動でキャンペーンを行う場合、以下のようなミスが起こりがちです。
              </p>
              <div className="blog__border-highlight">
                <ul>
                  <li>
                    複数の当選者に同じクーポンコードを送付してしまう。
                  </li>
                  <li>
                    どのユーザーに送ったのか分からなくなり、クーポンコードが余ってしまう。
                  </li>
                  <li>
                    送信ログが取れないため、履歴を追うことができない。
                  </li>
                </ul>
              </div>
              <p>
                何度も繰り返しプレゼントキャンペーンを実施する場合には、手間なく正確にDMを自動送信できるツールを利用するのがオススメです。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/what-is-ugc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    UGCとは？活用のメリットや上手な活用方法・注意点をわかりやすく解説！
                  </a>
                </p>
              </div>
              <h3 id="id14">Twitter DM 運用についてのまとめ</h3>
              <ul>
                <li>Twitterの自動化ルール・ガイドラインは遵守するべき。</li>
                <li>DMを自動配信するなら、一括配信ツールの利用がおすすめ。</li>
              </ul>
              <p>
                Twitterキャンペーンに関するお悩みがあれば、PARKLoT（パークロット）へ
                <a
                  href="https://www.park-lot.com/contact"
                  target="_blank"
                  rel="noreferrer"
                >
                  お気軽にご相談
                </a>
                ください。フォロワー増加、売上アップなどの成果にコミットできるように、具体的な施策を提案させていただきます。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/examples-of-sns-marketing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNSプロモーションを成功させるには？成功事例から学べる4つのポイント
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-choose-sns-marketing-tools"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNSマーケティングツールが必要な理由とは？分析ツールの選び方のキホンを初心者向けに紹介！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-message"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【当選通知テンプレを公開】次につながるTwitterキャンペーンのメッセージとは？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/rise-engagement-rate-with-retweet-or-like-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    驚きのエンゲージメント率！ リツイートいいねキャンペーンの魅力を徹底解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/campaign-for-food-and-restaurants"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【食品・飲食店特化】売上アップ！集客キャンペーン事例をわかりやすく解説
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-reduce-cost-of-sns-operations"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【Twitter企業アカウント】うまく運用代行を活用して業務コストを削減する方法とは？
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HowToAutomaticallySendDmForTwitterCampaigns
